import { z } from 'zod';

import { createBidirectionalMap, setDeepLinkUrlParams } from 'utils/deep-link';
import { ROUTER_PARAMS } from 'utils/router-params';

import { DeepLink } from 'types/DeepLink';
import { MarketAvailability } from 'types/MarketAvailability';
import { NftFilters, NftSortOrder } from 'types/Nft';
import { UnsafeAny } from 'types/utils';

const ATTRIBUTE_KEY = ROUTER_PARAMS.COLLECTION_ATTRIBUTES;
const MARKET_KEY = 'market';
const SORT_KEY = 'sort';
const CREATOR_KEY = ROUTER_PARAMS.COLLECTION_CREATOR;
const MEDIA_KEY = ROUTER_PARAMS.COLLECTION_DISTINCT_ASSET_KEY;

type NftDeepLinkKeys = Partial<
  Pick<
    NftFilters,
    | 'attributes'
    | 'creatorPublicKey'
    | 'marketAvailability'
    | 'media'
    | 'sortOrder'
  >
>;

const UrlMarketEnum = z.enum(['auction', 'buy', 'offer', 'reserve']);
const UrlSortEnum = z.enum(['newest', 'oldest', 'price-high', 'price-low']);

const marketFilterToUrlMap: Record<
  MarketAvailability,
  z.infer<typeof UrlMarketEnum>
> = {
  HAS_ACTIVE_BUY_NOW: 'buy',
  HAS_ACTIVE_OFFER: 'offer',
  LIVE_AUCTION: 'auction',
  RESERVE_NOT_MET: 'reserve',
};

const sortOrderToUrlMap: Record<
  Partial<NftSortOrder>,
  z.infer<typeof UrlSortEnum>
> = {
  MINT_DATE_ASC: 'oldest',
  MINT_DATE_DESC: 'newest',
  PRICE_ASC: 'price-low',
  PRICE_DESC: 'price-high',

  // Not used, but kept to appease TS
  DEFAULT: '' as UnsafeAny,
};

const marketMap = createBidirectionalMap(marketFilterToUrlMap);
const sortMap = createBidirectionalMap(sortOrderToUrlMap);

const nftUrlSchema = z.object({
  [ATTRIBUTE_KEY]: z.string().or(z.string().array().optional()),
  [CREATOR_KEY]: z.string().optional(),
  [MARKET_KEY]: UrlMarketEnum.optional(),
  [MEDIA_KEY]: z.string().optional(),
  [SORT_KEY]: UrlSortEnum.optional(),
});

export const nftUrlParams: DeepLink<NftDeepLinkKeys> = {
  get(query) {
    const result = nftUrlSchema.safeParse(query);
    if (!result.success) return null;

    const parsedData = result.data;

    return {
      attributes: normalizeMaybeArray(parsedData[ATTRIBUTE_KEY]),
      creatorPublicKey: parsedData[CREATOR_KEY] || null,
      marketAvailability: parsedData[MARKET_KEY]
        ? marketMap.toValue(parsedData[MARKET_KEY])
        : null,
      media: parsedData[MEDIA_KEY] || null,
      sortOrder: parsedData[SORT_KEY]
        ? sortMap.toValue(parsedData[SORT_KEY])
        : 'DEFAULT',
    };
  },
  set(values) {
    const params = new URLSearchParams();

    // Sort
    if (values.sortOrder && values.sortOrder !== 'DEFAULT') {
      params.set(SORT_KEY, sortMap.toUrl(values.sortOrder));
    }

    // Creator
    if (values.creatorPublicKey) {
      params.set(CREATOR_KEY, values.creatorPublicKey);
    }

    // Market filters
    if (values.marketAvailability) {
      params.set(MARKET_KEY, marketMap.toUrl(values.marketAvailability));
    }

    // Media (used for Stacks)
    if (values.media) {
      params.set(MEDIA_KEY, values.media);
    }

    // NFT Attributes
    if (values.attributes) {
      values.attributes.forEach((attr) => {
        params.append(ATTRIBUTE_KEY, attr);
      });
    }

    setDeepLinkUrlParams(params);
  },
};

const normalizeMaybeArray = (value: undefined | string | string[]) => {
  if (Array.isArray(value)) {
    return value;
  }

  if (value) {
    return [value];
  }

  return [];
};
