import { z } from 'zod';

import { maxDigitsAfterDecimal } from './forms/mint';
import { addressValueSchema } from './shared';

export const SPLITS_POSITIVE_MESSAGE =
  'The split percentage must be a positive number';

export const splitRecipientSchema = z.object({
  address: addressValueSchema,
  shareInPercentage: z.coerce
    .number()
    .positive({ message: SPLITS_POSITIVE_MESSAGE })
    .max(100, 'Cannot be more than 100%')
    .refine(
      maxDigitsAfterDecimal,
      'number field must have 2 digits after decimal or fewer'
    ),
});
