import { Formik, Form } from 'formik';

import Box from 'components/base/Box';
import Grid from 'components/base/Grid';
import CheckboxAndWrapper from 'components/forms/CheckboxAndWrapper';
import FormikSubmitButton from 'components/forms/FormikSubmitButton';

import {
  ApiUpdateCollectionHiddenAtVariables,
  useUpdateCollectionHiddenAt,
} from 'gql/api/mutations/update-collection-hidden-at.generated';
import { useCollectionByUuid } from 'gql/hasura/queries/collection-by-uuid.generated';
import { getFirstValue } from 'utils/helpers';

interface HideCollectionPaneProps {
  collectionId: string;
}

/**
 * TODO: replace this with a Button + confirm step.
 */
export default function HideCollectionPane(props: HideCollectionPaneProps) {
  const { collectionId } = props;

  const {
    data: collection,
    isLoading: isCollectionLoading,
    refetch: refetchCollection,
  } = useCollectionByUuid(
    { id: collectionId },
    {
      enabled: Boolean(collectionId),
      select: (data) => getFirstValue(data.collections),
    }
  );

  const { mutateAsync: updateCollectionHiddenAt } =
    useUpdateCollectionHiddenAt();

  const handleSubmit = async (values: ApiUpdateCollectionHiddenAtVariables) => {
    await updateCollectionHiddenAt({
      id: values.id,
      hidden: values.hidden,
    });
    await refetchCollection();
  };

  return (
    <Box>
      <Formik<ApiUpdateCollectionHiddenAtVariables>
        initialValues={{
          // @ts-expect-error null-checks
          id: collection?.id,
          hidden: Boolean(collection?.hiddenAt),
        }}
        onSubmit={handleSubmit}
        enableReinitialize={!isCollectionLoading}
      >
        <Form>
          <Grid css={{ gap: '$4' }}>
            <CheckboxAndWrapper
              name="hidden"
              label="Collection hidden"
              description="Should this collection be hidden?"
            />

            <FormikSubmitButton
              label="Hide collection"
              submittingLabel="Hiding collection…"
              submittedLabel="Collection hidden"
              variant="danger"
            />
          </Grid>
        </Form>
      </Formik>
    </Box>
  );
}
