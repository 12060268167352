import {
  CollectionByContractSlugDocument,
  CollectionByContractSlug,
  CollectionByContractSlugVariables,
} from 'gql/hasura/queries/collection-by-contract-slug.generated';
import {
  CollectionStats,
  CollectionStatsDocument,
  CollectionStatsVariables,
} from 'gql/hasura/queries/collection-stats.generated';
import {
  CollectionUniqueness,
  CollectionUniquenessDocument,
  CollectionUniquenessVariables,
} from 'gql/hasura/queries/collection-uniqueness.generated';
import { fndHasuraClient } from 'lib/clients/hasura-client';
import { getFirstValue } from 'utils/helpers';

export async function getCollectionByContractSlug(
  variables: CollectionByContractSlugVariables
) {
  const client = fndHasuraClient();
  const query = await client.request<
    CollectionByContractSlug,
    CollectionByContractSlugVariables
  >(CollectionByContractSlugDocument, variables);
  return getFirstValue(query.items);
}

export async function getCollectionStats(variables: CollectionStatsVariables) {
  const client = fndHasuraClient();
  return await client.request<CollectionStats, CollectionStatsVariables>(
    CollectionStatsDocument,
    variables
  );
}

export async function getUniqueCollectionByContractSlug(
  variables: CollectionUniquenessVariables
) {
  const client = fndHasuraClient();
  const query = await client.request<
    CollectionUniqueness,
    CollectionUniquenessVariables
  >(CollectionUniquenessDocument, variables);
  return getFirstValue(query.collections);
}
