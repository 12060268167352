import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';

import { useIsHydrated } from 'hooks/use-is-hydrated';
import { nftUrlParams } from 'schemas/url/nft';

import { INITIAL_NFT_FILTERS, useNftFilters } from './use-nft-filters';

export function useNftFiltersUrlSync(cacheKey: string) {
  const router = useRouter();
  const hasParsedUrlParams = useRef(false);
  const isHydrated = useIsHydrated();

  const { setFilters } = useNftFilters(cacheKey, {
    enabled: isHydrated,
    onSuccess: async (filters) => {
      if (!hasParsedUrlParams.current || !isHydrated) return;
      nftUrlParams.set(filters);
    },
  });

  useEffect(() => {
    // Return early if router is not ready, or page is not hydrated
    if (!router.isReady || !isHydrated) return;

    const urlFilters = nftUrlParams.get(router.query);

    if (urlFilters) {
      setFilters({
        ...INITIAL_NFT_FILTERS,
        ...urlFilters,
      });
    } else {
      setFilters(INITIAL_NFT_FILTERS);
    }

    hasParsedUrlParams.current = true;
  }, [router.isReady, isHydrated]);

  return null;
}
