import { styled } from '@f8n-frontend/stitches';

import Text from 'components/base/Text';

const SingleValue = styled(Text, {
  textAlign: 'right',
  whiteSpace: 'nowrap',
});

SingleValue.defaultProps = {
  weight: 'semibold',
  size: { '@initial': 2, '@bp2': 3 },
};

export default SingleValue;
