import { validateHTMLColorHex } from 'validate-color';
import * as Yup from 'yup';
import { z } from 'zod';

import { CreateCollectionFormValues } from 'components/transactions/collection/types';

import { SLUG_MAX_LENGTH } from 'lib/constants';
import { getUniqueCollectionByContractSlug } from 'queries/hasura/collections';
import { collectionSymbolSchema } from 'schemas/collection';
import { ipfsUrlSchema } from 'schemas/ipfs';
import { isAddress } from 'utils/address';
import {
  COLLECTION_NAME_MAX_LENGTH_ERROR,
  SYMBOL_CHARACTER_LIMIT_ERROR,
  SYMBOL_SPECIAL_CHARACTER_ERROR,
} from 'utils/collections';
import { debounce, isAllTrue, notEmptyOrNil } from 'utils/helpers';
import { checkIsValidSlug } from 'utils/slug';
import { areKeysEqual } from 'utils/users';

import { contractAddressField } from './generic';
import { COMMON_SCHEMA_FIELDS } from './shared';

export const CreateCollectionSchema: Yup.SchemaOf<CreateCollectionFormValues> =
  Yup.object().shape({
    name: Yup.string()
      .required('A collection name is required')
      .max(48, COLLECTION_NAME_MAX_LENGTH_ERROR),
    symbol: Yup.string()
      .required('A collection symbol is required')
      .test('slug-valid', SYMBOL_SPECIAL_CHARACTER_ERROR, checkIsValidSlug)
      .max(8, SYMBOL_CHARACTER_LIMIT_ERROR),
  });

interface EditCollectionSchemaArgs {
  initialSlug: string;
}

export const EditCollectionSchema = (args: EditCollectionSchemaArgs) =>
  Yup.object().shape({
    data: Yup.object().shape({
      contractAddress: contractAddressField,
      brandColor: Yup.string()
        .nullable()
        .optional()
        .test('brand-color-valid', 'Invalid hex color', checkIsValidHexColor),
      collectionImageUrl: Yup.string().url('Is not a valid URL').nullable(),
      coverImageUrl: Yup.string().url('Is not a valid URL').nullable(),
      description: Yup.string().max(
        500,
        'Cannot be more than ${max} characters'
      ),
      slug: Yup.string()
        .required('A collection URL is required')
        .min(1, 'Must be at least one character')
        .max(SLUG_MAX_LENGTH, 'Cannot be more than ${max} characters')
        .test(
          'not-address',
          'Must not be an Ethereum address',
          (value: string) => {
            return !isAddress(value);
          }
        )
        .test('slug-valid', 'Collection URL is invalid', checkIsValidSlug)
        .test(
          'slug-available',
          'This URL is already taken',
          async (value: string) => {
            return new Promise((resolve) => {
              checkUniqueness({
                resolve,
                value,
                initialValue: args.initialSlug,
              });
            });
          }
        ),
    }),
  });

const checkIsValidHexColor = (hexColor: string | undefined | null) => {
  if (!hexColor) return true;

  return hexColor.startsWith('#')
    ? validateHTMLColorHex(hexColor)
    : validateHTMLColorHex(`#${hexColor}`);
};

interface CheckUniquenessOptions {
  value: string;
  initialValue: string;
  resolve: (arg0: boolean) => void;
}

const checkUniqueness = debounce((args: CheckUniquenessOptions) => {
  const { value, initialValue, resolve } = args;

  const hasValue = notEmptyOrNil(value);
  const isValueOriginal = areKeysEqual([value, initialValue]);
  const canCheckUniqueness = isAllTrue([hasValue, !isValueOriginal]);

  if (canCheckUniqueness) {
    // return false when a collection is found (slug is taken)
    getUniqueCollectionByContractSlug({
      slug: value,
    }).then((collection) => resolve(!collection));
  } else {
    // otherwise return true (slug is available)
    resolve(true);
  }
}, 250);

export const create721CollectionSchema = z.object({
  name: COMMON_SCHEMA_FIELDS.COLLECTION_NAME,
  symbol: collectionSymbolSchema,
  chainId: COMMON_SCHEMA_FIELDS.CHAIN_ID,
});

export type Create721CollectionValues = z.infer<
  typeof create721CollectionSchema
>;

export const create1155CollectionSchema = z.object({
  name: COMMON_SCHEMA_FIELDS.COLLECTION_NAME,
  chainId: COMMON_SCHEMA_FIELDS.CHAIN_ID,
  assetIpfsPath: ipfsUrlSchema,
});

export type Create1155CollectionValues = z.infer<
  typeof create1155CollectionSchema
>;
