import { ActivityType } from 'types/Activity';

export const activityTypeLabelMap: Record<ActivityType, string> = {
  auction: 'Auction',
  buy_now: 'Buy Now',
  list: 'List',
  mint: 'Mint',
  offer: 'Offer',
  private_sale: 'Private Sale',
};
