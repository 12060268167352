import { UseQueryOptions } from '@tanstack/react-query';

import {
  useCollectionByContractSlug as useCollectionByContractSlugBaseHook,
  CollectionByContractSlug,
  CollectionByContractSlugVariables,
} from 'gql/hasura/queries/collection-by-contract-slug.generated';
import { isQueryEnabled } from 'hooks/queries/shared';
import { getFirstValue, isAllTrue } from 'utils/helpers';

import { DeepNullable } from 'types/utils';

type Collection = CollectionByContractSlug['items'][number];

export default function useCollectionByContractSlug(
  variables: DeepNullable<CollectionByContractSlugVariables>,
  options: Pick<
    UseQueryOptions<CollectionByContractSlug, Error, Collection>,
    'refetchOnWindowFocus' | 'initialData' | 'enabled' | 'refetchOnReconnect'
  >
) {
  return useCollectionByContractSlugBaseHook(
    variables as CollectionByContractSlugVariables,
    {
      ...options,
      select: (data) => getFirstValue(data.items),
      enabled: isAllTrue([variables.contractSlug, isQueryEnabled(options)]),
    }
  );
}

useCollectionByContractSlug.getKey = useCollectionByContractSlugBaseHook.getKey;
