import { SearchResponse } from '@algolia/client-search';
import { styled } from '@f8n-frontend/stitches';
import { toPairs } from 'ramda';
import type { Address } from 'viem';

import Accordion from 'components/base/Accordion';
import Button from 'components/base/Button';
import ButtonFilterIndicator from 'components/base/ButtonFilterIndicator';
import Flex from 'components/base/Flex';
import Link from 'components/base/Link';
import Modal from 'components/base/Modal';
import Text from 'components/base/Text';
import MultiSelectOption from 'components/filters/MultiSelectOption';

import PropertiesIcon from 'assets/icons/properties-icon.svg';
import { useNftFilters } from 'hooks/filters/use-nft-filters';
import { notEmptyOrNil } from 'utils/helpers';

type AttributeValue = {
  label: string;
  value: string;
  count: number | null;
};

type AttributeCategory = {
  category: string;
  attributes: AttributeValue[];
};

interface CollectionAttributeModalProps {
  categories: AttributeCategory[];
  contractAddress: Address;
  nftCount: number;
}

export default function CollectionAttributeModal(
  props: CollectionAttributeModalProps
) {
  const { categories, contractAddress, nftCount } = props;

  const { filters, resetAttributesFilter, setFilters } =
    useNftFilters(contractAddress);

  return (
    <Modal.Root>
      <Modal.Trigger asChild>
        <AttributesButton size={0} type="button">
          <PropertiesIcon />
          <span>Attributes</span>
          <ButtonFilterIndicator
            applied={filters.attributes?.length > 0 || false}
          />
        </AttributesButton>
      </Modal.Trigger>
      <Modal.Content
        size={0}
        header={<Modal.Header title="Attributes" />}
        footer={
          <Modal.Footer>
            <Modal.Close asChild>
              <Link
                onClick={() => resetAttributesFilter()}
                variant="strong"
                weight="semibold"
              >
                Clear
              </Link>
            </Modal.Close>
            <Modal.Close asChild>
              <Button variant="primary" type="submit">
                Done
              </Button>
            </Modal.Close>
          </Modal.Footer>
        }
      >
        <Accordion.Root type="multiple">
          {categories.map(({ attributes, category }) => (
            <Accordion.Item key={category} value={category}>
              <Accordion.Header>
                <Accordion.Trigger name={category}>
                  <SelectedAttributes
                    attributes={getSelectedAttributes(
                      attributes,
                      filters.attributes
                    )}
                  />
                </Accordion.Trigger>
              </Accordion.Header>
              <Accordion.Content
                css={{
                  paddingX: '$3',
                  paddingTop: 0,
                  paddingBottom: '$3',
                }}
              >
                {attributes.map((attr) => {
                  const count = attr.count ?? 0;
                  return (
                    <MultiSelectOption
                      key={attr.value}
                      count={count}
                      checked={filters.attributes.includes(attr.value)}
                      label={attr.label}
                      percentage={count / nftCount}
                      onCheckedChange={() => {
                        setFilters({
                          attributes: withOrWithoutValue(
                            attr.value,
                            filters.attributes
                          ),
                        });
                      }}
                    />
                  );
                })}
              </Accordion.Content>
            </Accordion.Item>
          ))}
        </Accordion.Root>
      </Modal.Content>
    </Modal.Root>
  );
}

interface SelectedAttributesProps {
  attributes: string[];
}

function SelectedAttributes(props: SelectedAttributesProps) {
  const { attributes } = props;

  const hasAttributes = notEmptyOrNil(attributes);

  if (!hasAttributes) {
    return null;
  }

  return (
    <SelectedAttributesContainer>
      <SelectedAttributesText>{attributes.join(', ')}</SelectedAttributesText>
    </SelectedAttributesContainer>
  );
}

const AttributesButton = styled(Button, {
  defaultVariants: {
    icon: true,
    variant: 'outline',
  },
});

const SelectedAttributesContainer = styled(Flex, {
  flex: 1,
  marginLeft: '$2',
  minWidth: 0,
});

const SelectedAttributesText = styled(Text, {
  maxWidth: '95%',
});

SelectedAttributesText.defaultProps = {
  color: 'dim',
};

SelectedAttributesText.defaultProps = {
  size: 0,
  ellipsis: true,
  weight: 'regular',
};

function getSelectedAttributes(
  attributes: AttributeValue[],
  selectedAttributes: string[]
) {
  return attributes
    .filter((attr) =>
      selectedAttributes.some((innerAttr) => innerAttr === attr.value)
    )
    .map((attr) => attr.label);
}

function withOrWithoutValue<T extends string>(newValue: T, values: T[]) {
  const hasAppliedFilter = values.includes(newValue);

  return hasAppliedFilter
    ? values.filter((value) => value !== newValue)
    : [...values, newValue];
}

export function getFacetValues<T extends SearchResponse<unknown>>(res: T) {
  if (!res.facets) {
    return [];
  }

  const sortedFacets = Object.keys(res.facets).sort((a, b) =>
    a.localeCompare(b)
  );

  return sortedFacets.map((facetKey) => {
    const [, attributeName] = facetKey.split('attributes.');
    // @ts-expect-error null-checks
    const attributes = toPairs(res.facets[facetKey]);
    return {
      category: attributeName as string,
      attributes: attributes
        .sort(([a], [b]) => {
          return a.localeCompare(b);
        })
        .map(([label, count]) => ({
          value: `${facetKey}:${label}`,
          label,
          count,
        })),
    };
  });
}
