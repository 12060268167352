import { useQuery } from '@tanstack/react-query';

import {
  CollectionArtworkCreator as CollectionArtworkCreatorQuery,
  CollectionArtworkCreatorDocument,
  CollectionArtworkCreatorVariables,
} from 'gql/hasura/queries/collection-artwork-creator.generated';
import { fndHasuraClient } from 'lib/clients/hasura-client';
import { getFirstValue, isAllTrue } from 'utils/helpers';

export type CollectionArtworkCreator =
  CollectionArtworkCreatorQuery['creators'][number];

async function getCollectionArtworkCreator(
  variables: CollectionArtworkCreatorVariables
) {
  const client = fndHasuraClient();
  const response = await client.request<
    CollectionArtworkCreatorQuery,
    CollectionArtworkCreatorVariables
  >(CollectionArtworkCreatorDocument, variables);

  return getFirstValue(response.creators);
}

export default function useCollectionArtworkCreator(
  variables: CollectionArtworkCreatorVariables
) {
  const { contractAddress, artworkCreatorPublicKey } = variables;

  return useQuery({
    queryKey: useCollectionArtworkCreator.getKey(variables),
    queryFn: () => {
      return getCollectionArtworkCreator({
        contractAddress,
        artworkCreatorPublicKey,
      });
    },
    enabled: isAllTrue([contractAddress, artworkCreatorPublicKey]),
  });
}

useCollectionArtworkCreator.getKey = (
  variables: CollectionArtworkCreatorVariables
) => ['CollectionArtworkCreatorFacetEnrichment', variables];
