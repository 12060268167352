import { z } from 'zod';

import { IPFS_HASH_REGEX } from 'lib/constants';

export const ipfsHashSchema = z.string().regex(IPFS_HASH_REGEX);

export const ipfsUrlSchema = z
  .string()
  .url()
  .refine(
    (url) => {
      return url.split('/').some((urlPart) => {
        return ipfsHashSchema.safeParse(urlPart).success;
      });
    },
    {
      message: 'Invalid IPFS URL or IPFS hash',
    }
  );
