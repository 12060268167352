import { useQueryClient } from '@tanstack/react-query';
import { Form, Formik, useField } from 'formik';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { validateHTMLColorHex } from 'validate-color';
import type { Address } from 'viem';

import Box from 'components/base/Box';
import Button, { ButtonProps } from 'components/base/Button';
import Grid from 'components/base/Grid';
import InputField from 'components/base/InputField';
import Modal from 'components/base/Modal';
import FileUpload from 'components/forms/FileUpload';
import FormikSubmitButton from 'components/forms/FormikSubmitButton';
import SlugField from 'components/forms/fields/SlugField';
import TextAreaField from 'components/forms/fields/TextAreaField';

import {
  useUpdateCollection,
  ApiUpdateCollectionVariables,
} from 'gql/api/mutations/update-collection.generated';
import { ApiCollectionSaleType } from 'gql/api/types-api.generated';
import { useCollectionByContractAddress } from 'gql/hasura/queries/collection-by-contract-address.generated';
import { CollectionByContractSlug } from 'gql/hasura/queries/collection-by-contract-slug.generated';
import { selectCollectionByContractAddress } from 'hooks/queries/hasura/collections/use-collection-by-contract-address';
import useCollectionByContractSlug from 'hooks/queries/hasura/collections/use-collection-by-contract-slug';
import { EditCollectionSchema } from 'schemas/forms/collection';
import { ENV } from 'utils/env';
import { mbToBytes } from 'utils/helpers';
import { getPath } from 'utils/router';

interface EditCollectionModalProps {
  contractAddress: Address;
  collectionSaleType: ApiCollectionSaleType | null;
  size?: ButtonProps['size'];
  variant?: ButtonProps['variant'];
  onUpdateSuccess?(): void;
}

export default function EditCollectionModal(props: EditCollectionModalProps) {
  const {
    contractAddress,
    variant,
    size,
    collectionSaleType,
    onUpdateSuccess,
  } = props;

  const router = useRouter();
  const queryClient = useQueryClient();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data: collectionData, refetch: refetchCollection } =
    useCollectionByContractAddress(
      { contractAddress },
      {
        enabled: isModalOpen,
        refetchOnWindowFocus: false,
        select: selectCollectionByContractAddress,
      }
    );

  const { mutateAsync: updateCollectionMutation } = useUpdateCollection();

  const updateCollection = async (values: ApiUpdateCollectionVariables) => {
    await updateCollectionMutation({
      data: {
        ...values.data,
        chainId: collectionData?.chainId ?? ENV.PRIMARY_CHAIN_ID,
      },
    });

    const { data: collection } = await refetchCollection();

    if (onUpdateSuccess) {
      onUpdateSuccess();
    }

    if (collection?.slug) {
      // update the main collection page data via the query cache
      queryClient.setQueryData<CollectionByContractSlug>(
        useCollectionByContractSlug.getKey({
          contractSlug: collection.slug,
          indexedStates: [true, false],
        }),
        { items: [collection] }
      );

      // close the modal
      setIsModalOpen(false);
      await router.push(getPath.collection.page(collection.slug));
    }
  };

  return (
    <Modal.Root open={isModalOpen} onOpenChange={setIsModalOpen}>
      <Modal.Trigger asChild>
        <Button variant={variant} type="button" size={size}>
          Edit collection
        </Button>
      </Modal.Trigger>
      <Modal.Portal>
        <Modal.BlurOverlay />
        <Modal.PositionOverlay>
          <Formik<ApiUpdateCollectionVariables>
            validationSchema={EditCollectionSchema({
              initialSlug: collectionData?.slug as string,
            })}
            onSubmit={updateCollection}
            enableReinitialize
            initialValues={{
              data: {
                contractAddress,
                description: collectionData?.description ?? '',
                collectionImageUrl: collectionData?.collectionImageUrl ?? '',
                coverImageUrl: collectionData?.coverImageUrl ?? '',
                slug: collectionData?.slug ?? '',
                brandColor: collectionData?.brandColor ?? '',
              },
            }}
          >
            <EditCollectionForm collectionSaleType={collectionSaleType} />
          </Formik>
        </Modal.PositionOverlay>
      </Modal.Portal>
    </Modal.Root>
  );
}

interface EditCollectionFormProps {
  collectionSaleType: EditCollectionModalProps['collectionSaleType'];
}

// TODO: Convert to zod and react form
function EditCollectionForm(props: EditCollectionFormProps) {
  const { collectionSaleType } = props;

  const [field, meta, helpers] = useField<string>('data.brandColor');

  const formatColor = (value: string): string => {
    if (!value || !validateHTMLColorHex(value)) return '';

    if (value.startsWith('#')) {
      return value.slice(1);
    } else {
      return value;
    }
  };

  const getColor = (value: string | null): string => {
    if (!value) return '';
    const hexValue = value.startsWith('#') ? value : `#${value}`;
    return validateHTMLColorHex(hexValue) ? hexValue : '';
  };

  useEffect(() => {
    helpers.setValue(getColor(field.value));
  }, [field.value]);

  return (
    <Form>
      <Modal.Window
        header={<Modal.Header title="Edit Collection" />}
        footer={
          <Modal.Footer>
            <FormikSubmitButton
              label="Save changes"
              size={1}
              submittedLabel="Saved changes"
              submittingLabel="Saving changes…"
            />
          </Modal.Footer>
        }
      >
        <Grid css={{ gap: '$7', '> *': { minWidth: 0 } }}>
          {collectionSaleType === 'LINEAR_DUTCH_AUCTION' && (
            <InputField
              {...field}
              value={formatColor(field.value)}
              error={meta.error}
              id="brand-color"
              label="Brand color"
              placeholder="000000"
              prefix="#"
              tooltip="Paste in a valid hex code"
              suffix={
                <Box
                  style={{
                    backgroundColor: getColor(field.value),
                  }}
                  css={{
                    width: '$avatar2',
                    height: '$avatar2',
                    borderRadius: '$1',
                  }}
                />
              }
              size={2}
              type="text"
            />
          )}
          <FileUpload.S3Formik
            label="Collection logo"
            name="data.collectionImageUrl"
            bucket="collections"
            maxSizeInBytes={mbToBytes(10)}
            accept={['image/jpeg', 'image/jpg', 'image/gif', 'image/png']}
            required={false}
          />
          <FileUpload.S3Formik
            label="Cover image"
            name="data.coverImageUrl"
            bucket="collections"
            maxSizeInBytes={mbToBytes(10)}
            accept={['image/jpeg', 'image/jpg', 'image/gif', 'image/png']}
            required={false}
          />
          <TextAreaField.Formik
            label="Description"
            name="data.description"
            tip="Use markdown syntax to embed links"
            maxLength={500}
            rows={5}
          />
          <SlugField
            label="Collection URL"
            name="data.slug"
            type="collection"
          />
        </Grid>
      </Modal.Window>
    </Form>
  );
}
