import { styled } from '@f8n-frontend/stitches';

import { getGridSpacingStyles } from 'utils/styles';

const Root = styled('div', {
  marginTop: -1,
  ...getGridSpacingStyles('marginBottom'),
});

const MobileOnly = styled('div', {
  '@bp2': {
    display: 'none',
  },
});

const AboveMobileOnly = styled('div', {
  '@bp2-max': {
    display: 'none',
  },
});

interface ResponsiveProps {
  mobile: JSX.Element;
  aboveMobile: JSX.Element;
}
function Responsive(props: ResponsiveProps) {
  const { mobile, aboveMobile } = props;

  return (
    <>
      <MobileOnly>{mobile}</MobileOnly>
      <AboveMobileOnly>{aboveMobile}</AboveMobileOnly>
    </>
  );
}

const FiltersSection = {
  Root,
  Responsive,
};

export default FiltersSection;
