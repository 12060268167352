import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { AlgoliaArtwork, AlgoliaSearchIndex } from 'types/Algolia';

import { AlgoliaSearchIndexOptions, getAlgoliaSearchResults } from './shared';
import { AlgoliaArtworksSearch } from './use-algolia-artworks';

interface AlgoliaArtworksVariables {
  searchTerm: string;
  searchIndex: AlgoliaSearchIndex;
  options?: AlgoliaSearchIndexOptions;
}

export default function useAlgoliaFacets<TData = AlgoliaArtworksSearch>(
  variables: AlgoliaArtworksVariables,
  options: Pick<
    UseQueryOptions<AlgoliaArtworksSearch, Error, TData, string[]>,
    'enabled' | 'refetchOnWindowFocus' | 'placeholderData'
  > = {}
) {
  return useQuery({
    ...options,
    queryKey: [
      'algolia',
      'facets',
      variables.searchTerm,
      variables.searchIndex,
    ],
    queryFn: () => {
      return getAlgoliaSearchResults<AlgoliaArtwork>({
        index: variables.searchIndex,
        query: variables.searchTerm,
        options: { facets: ['*'], ...variables.options },
      });
    },
  });
}
