import * as Yup from 'yup';
import { z } from 'zod';

import { URL_REGEX } from 'lib/constants';
import { isAddress } from 'utils/address';

export const LinksSchema = Yup.object().shape({
  website: Yup.object().shape({
    platform: Yup.string(),
    handle: Yup.string()
      .matches(URL_REGEX, 'That URL doesn’t look valid')
      .nullable(),
  }),
  instagram: Yup.object().shape({
    platform: Yup.string(),
    handle: Yup.string().nullable(),
  }),
  twitter: Yup.object().shape({
    platform: Yup.string(),
    handle: Yup.string().nullable(),
  }),
  youtube: Yup.object().shape({
    platform: Yup.string(),
    handle: Yup.string()
      .matches(URL_REGEX, 'That URL doesn’t look valid')
      .nullable(),
  }),
  facebook: Yup.object().shape({
    platform: Yup.string(),
    handle: Yup.string().nullable(),
  }),
  twitch: Yup.object().shape({
    platform: Yup.string(),
    handle: Yup.string().nullable(),
  }),
  tiktok: Yup.object().shape({
    platform: Yup.string(),
    handle: Yup.string().nullable(),
  }),
  discord: Yup.object().shape({
    platform: Yup.string(),
    handle: Yup.string()
      /**
       * Validates discord usernames against two specific formats:
       *
       * @example
       * format 1 examples
       * 'user1234#5678'; // Allowed
       * 'user1234#123';  // Not Allowed
       *
       * @example
       * @see https://support.discord.com/hc/en-us/articles/12620128861463#h_01GXPQAGG6W477HSC5SR053QG1
       * format 2 examples
       * 'username';      // Allowed
       * 'Username';      // Not Allowed
       */
      .matches(
        /^((.+?)#\d{4}|(?!.*?\.{2,})[a-z0-9_\\.]{2,32})$/,
        'That handle doesn’t look valid'
      )
      .nullable(),
  }),
  snapchat: Yup.object().shape({
    platform: Yup.string(),
    handle: Yup.string()
      .matches(
        // https://stackoverflow.com/questions/39819830/what-are-the-allowed-character-in-snapchat-username
        /^[a-zA-Z][\w-_.]{1,13}[\w]$/g,
        'That handle doesn’t look valid'
      )
      .nullable(),
  }),
});

export const contractAddressField = Yup.string()
  .required('Contract address is required')
  .test('valid-address', 'Contract address is invalid', (address) =>
    address ? isAddress(address) : false
  );

export const tokenIdSchema = z.coerce.number().int().safe().nonnegative();
