import { RemoveIcon } from '@f8n/icons';

import DropdownMenu from 'components/base/DropdownMenu';

import useModal from 'hooks/use-modal';

import { CollectionFilter } from 'types/Collection';

export function SelfDestructCollectionMenuItem(props: {
  collection: CollectionFilter;
}) {
  const { collection } = props;
  const { setModal } = useModal();

  return (
    <DropdownMenu.Item
      type="button"
      onClick={() => {
        setModal({
          type: 'SELF_DESTRUCT_COLLECTION',
          chainId: collection.chainId,
          contractAddress: collection.contractAddress,
        });
      }}
      variant="danger"
    >
      <RemoveIcon />
      Self-destruct
    </DropdownMenu.Item>
  );
}
