import { AdminIcon } from '@f8n/icons';

import DropdownMenu from 'components/base/DropdownMenu';
import useNotifications from 'state/stores/notifications';

import { useClearStagedAssets } from 'gql/api/mutations/clear_staged_assets.generated';
import { useRefreshCollectionOwner } from 'gql/api/mutations/refresh-collection-owner.generated';
import useModal from 'hooks/use-modal';
import { getCollectionFilter } from 'utils/inputs';

import { CollectionFilter } from 'types/Collection';

function AdminMenuItem(props: { children: string; onClick: () => void }) {
  return (
    <DropdownMenu.Item type="button" onClick={props.onClick}>
      <AdminIcon />
      {props.children}
    </DropdownMenu.Item>
  );
}

function AdminOnlyRefreshCollectionOwner(props: {
  collection: CollectionFilter;
}) {
  const notification = useNotifications();

  const refreshOwnership = useRefreshCollectionOwner({
    onMutate: () => {
      notification.show.info({
        message: 'Refreshing owner…',
      });
    },
    onSuccess: () => {
      notification.show.success({
        message: 'Owner refreshed',
      });
    },
    onError: () => {
      notification.show.error({
        message: 'Error refreshing ownership',
      });
    },
  });

  return (
    <AdminMenuItem
      onClick={() => {
        if (refreshOwnership.isPending) {
          return;
        }

        refreshOwnership.mutate(getCollectionFilter(props.collection));
      }}
    >
      Refresh ownership
    </AdminMenuItem>
  );
}

function AdminOnlyClearDropStagedAssets(props: {
  collection: CollectionFilter;
}) {
  const notification = useNotifications();

  const clearStagedAssets = useClearStagedAssets({
    onMutate: () => {
      notification.show.info({
        message: 'Clearing staged assets…',
      });
    },
    onSuccess: () => {
      notification.show.success({
        message: 'Staged assets cleared',
      });
    },
    onError: () => {
      notification.show.error({
        message: 'Error clearing staged assets',
      });
    },
  });

  return (
    <AdminMenuItem
      onClick={() => {
        if (clearStagedAssets.isPending) {
          return;
        }

        clearStagedAssets.mutate({
          collection: getCollectionFilter(props.collection),
        });
      }}
    >
      Clear Drop staged assets
    </AdminMenuItem>
  );
}

export function AdminToolsMenuItem() {
  const { setModal } = useModal();

  return (
    <AdminMenuItem onClick={() => setModal({ type: 'ADMIN_TOOLS' })}>
      Admin tools
    </AdminMenuItem>
  );
}

export const AdminOnlyCollectionMenuItem = {
  RefreshOwner: AdminOnlyRefreshCollectionOwner,
};

export const AdminOnlyDropMenuItem = {
  ClearStagedAssets: AdminOnlyClearDropStagedAssets,
};
