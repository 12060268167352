import slugify from 'underscore.string/slugify';

import { areKeysEqual } from './users';

export const checkIsValidSlug = (slug: string) => {
  // slugify the URL
  const slugifiedValue = slugify(slug);
  // return true when both the current value
  // and slugified version are equal
  return areKeysEqual([slugifiedValue, slug]);
};
