import { useField } from 'formik';

import InputField from 'components/base/InputField';

interface SlugFieldProps {
  disabled?: boolean;
  label: string;
  name: string;
  type: 'collection' | 'gallery';
}

export default function SlugField(props: SlugFieldProps) {
  const { label, name, disabled, type } = props;

  const [field, meta] = useField(name);

  return (
    <InputField
      {...field}
      error={meta.error}
      touched={meta.touched}
      id={name}
      label={label}
      prefix={`foundation.app/${type}/`}
      size={2}
      type="text"
      disabled={disabled}
      name={name}
    />
  );
}
