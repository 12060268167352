import * as Yup from 'yup';

import { MintFormValues } from 'components/transactions/mint/types';

import { countDecimals } from 'utils/formatters';

import { SplitRecipient } from 'types/split';

import { contractAddressField } from './generic';

export const SPLITS_POSITIVE_MESSAGE =
  'The split percentage must be a positive number';

export const maxDigitsAfterDecimal = (share: number | undefined) => {
  const numberOfDecimals = share ? countDecimals(share) : 0;
  return numberOfDecimals <= 2;
};

export const sumOfSplits = (shares: SplitRecipient[]) => {
  if (shares.length === 0) {
    return true;
  }
  const reducer = (accumulator: number, currentValue: SplitRecipient) =>
    accumulator + currentValue.shareInPercentage;
  const sum = shares.reduce(reducer, 0);
  return sum === 100;
};

const ShareObject = Yup.object().shape({
  address: Yup.string().required(),
  shareInPercentage: Yup.number()
    .required()
    .positive(SPLITS_POSITIVE_MESSAGE)
    .max(100, 'Cannot be more than 100%')
    .test(
      'maxDigitsAfterDecimal',
      'number field must have 2 digits after decimal or fewer',
      maxDigitsAfterDecimal
    ),
});

const splitsSchema = Yup.array()
  .of(ShareObject)
  .when('splitsEnabled', {
    is: true,
    then: Yup.array()
      .min(2, 'You need at least two recipients')
      .max(4, 'You can have at most four recipients')
      .test(
        'sum-of-splits',
        'The split percentages need to add up to 100',
        sumOfSplits
      ),
    otherwise: Yup.array(),
  });

// @ts-expect-error [yup]
export const MintArtworkSchema: Yup.SchemaOf<MintFormValues> =
  Yup.object().shape({
    contractAddress: contractAddressField,
    name: Yup.string()
      .min(1, 'Must be at least one character')
      .nullable()
      .max(50, 'Must be less than 50 characters')
      .required('NFT name is required'),
    // when the artwork status is MINTING or MINTED we block the form
    status: Yup.string()
      .required()
      .equals(['DRAFT', 'FAILED'], 'NFT has already been minted'),
    description: Yup.string()
      .max(1000, 'Cannot be more than 1000 characters')
      .nullable(),
    splitsEnabled: Yup.bool().required(),
    splits: splitsSchema,
    // block the form when connecting to pinata
    hasPinataKey: Yup.bool().equals([true], 'Connecting to IPFS…'),
  });
