import { useSimulateContract } from 'wagmi';

import { NFTCollection } from 'lib/abis/NFTCollection';
import { ChainId } from 'lib/chains';
import { getAddress } from 'utils/address';

type Variables = {
  chainId: ChainId;
  contractAddress: string;
};

type Options = {
  enabled: boolean;
};

export default function useCanSelfDestructCollection(
  variables: Variables,
  options: Options
) {
  return useSimulateContract({
    abi: NFTCollection,
    address: getAddress(variables.contractAddress),
    chainId: variables.chainId,
    functionName: 'selfDestruct',
    query: {
      enabled: options.enabled,
      retry: false,
    },
  });
}
