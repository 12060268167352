import { config, styled } from '@f8n-frontend/stitches';
import { keepPreviousData } from '@tanstack/react-query';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useVirtualizer } from '@tanstack/react-virtual';
import NextLink from 'next/link';
import { useCallback, useMemo, useRef } from 'react';
import { useMedia } from 'react-use';

import Empty from 'components/Empty';
import SpinnerStroked from 'components/SpinnerStroked';
import ActivityBadge from 'components/activity/ActivityBadge';
import Box from 'components/base/Box';
import CollectionLogo from 'components/base/CollectionLogo';
import Dash from 'components/base/Dash';
import Flex from 'components/base/Flex';
import Link from 'components/base/Link';
import Table from 'components/base/Table';
import Text from 'components/base/Text';
import EthValue from 'components/collections/EthValue';
import { ActivityIndicator } from 'components/feed/InfiniteScrollButton';
import UserTag from 'components/users/UserTag';

import {
  CollectionSalesHistory,
  useInfiniteCollectionSalesHistory,
} from 'gql/hasura/queries/collection-sales-history.generated';
import useFetchVirtualizedData from 'hooks/use-fetch-virtualized-data';
import { buildArtworkAssetUrl, buildPosterUrl } from 'utils/assets';
import { formatRelativeTimestamp } from 'utils/dates/dates';
import { isNumberType } from 'utils/helpers';
import { hasuraPaginator } from 'utils/react-query';
import { getPath } from 'utils/router';

import { NftFilter } from 'types/Nft';

type SalesHistoryItem = CollectionSalesHistory['items'][number];

interface CollectionSalesHistoryProps {
  contractAddress: string;
}

const salesColumnHelper = createColumnHelper<SalesHistoryItem>();

export default function CollectionSalesHistoryTable(
  props: CollectionSalesHistoryProps
) {
  const { contractAddress } = props;

  const isMobile = useMedia(config.media['bp2-max']);

  const parentRef = useRef<HTMLDivElement>(null);

  const collectionSalesHistoryQuery = useInfiniteCollectionSalesHistory(
    {
      contractAddress: contractAddress,
      ...hasuraPaginator.initialPageParam,
    },
    {
      initialPageParam: hasuraPaginator.initialPageParam,
      getNextPageParam: hasuraPaginator.getNextPageParam,
      placeholderData: keepPreviousData,
    }
  );

  const salesData = useMemo(
    () => hasuraPaginator.flattenItems(collectionSalesHistoryQuery.data),
    [collectionSalesHistoryQuery.data]
  );

  const rowVirtualizer = useVirtualizer({
    count: salesData.length,
    getScrollElement: () => parentRef.current,
    estimateSize: useCallback(() => (isMobile ? 58 : 92), [isMobile]),
    overscan: 5,
  });

  useFetchVirtualizedData({
    data: salesData,
    virtualItems: rowVirtualizer.getVirtualItems(),
    hasNextPage: Boolean(collectionSalesHistoryQuery.hasNextPage),
    isFetchingNextPage: collectionSalesHistoryQuery.isFetchingNextPage,
    fetchNextPage: collectionSalesHistoryQuery.fetchNextPage,
  });

  const table = useReactTable({
    initialState: {
      sorting: useMemo(() => [{ id: 'dateSold', desc: true }], []),
    },
    state: {
      columnVisibility: isMobile
        ? {
            buyer: false,
            seller: false,
            eventType: false,
          }
        : {},
    },
    data: salesData,
    columns: useMemo(
      () => [
        salesColumnHelper.accessor('eventType', {
          header: () => (
            <Table.HeaderCellText align="left">Type</Table.HeaderCellText>
          ),
          cell: (cell) => (
            <Flex>
              <ActivityBadge value={cell.getValue()} />
            </Flex>
          ),
          enableSorting: false,
          size: 80,
        }),
        salesColumnHelper.accessor('artwork', {
          header: () => (
            <Table.HeaderCellText align="left">Artwork</Table.HeaderCellText>
          ),
          cell: (cell) => {
            const artwork = cell.row.original.artwork;

            if (!artwork) return <span />;

            // TODO: remove type cast after moving this data to API
            const href = getPath.token.page({
              chainId: artwork.chainId,
              contractAddress: artwork.contractAddress,
              tokenId: artwork.tokenId,
            } as NftFilter);

            return (
              <NextLink href={href} passHref>
                <Link title={artwork?.name ? artwork.name : undefined}>
                  <ArtworkTableCell artwork={artwork} />
                </Link>
              </NextLink>
            );
          },
          enableSorting: false,
          size: isMobile ? 120 : 200,
        }),
        salesColumnHelper.accessor('seller', {
          header: () => (
            <Table.HeaderCellText align="left">From</Table.HeaderCellText>
          ),
          cell: ({ row, cell }) => (
            <UserTableCell
              // If the event is a mint event, the "From" column should be empty
              user={
                row.original.eventType === 'mint' ? undefined : cell.getValue()
              }
            />
          ),
          enableSorting: false,
        }),
        salesColumnHelper.accessor('buyer', {
          header: () => (
            <Table.HeaderCellText align="left">To</Table.HeaderCellText>
          ),
          cell: ({ cell }) => <UserTableCell user={cell.getValue()} />,
          enableSorting: false,
        }),
        salesColumnHelper.accessor('priceLastSoldFor', {
          header: () => (
            <Table.HeaderCellText align="left">Price</Table.HeaderCellText>
          ),
          cell: ({ cell }) =>
            isNumberType(cell.getValue()) ? (
              <EthValue
                ethValue={cell.getValue()}
                textAlign="left"
                type="price"
              />
            ) : (
              <Dash />
            ),
          enableSorting: true,
          size: isMobile ? 80 : 100,
        }),
        salesColumnHelper.accessor('dateSold', {
          header: () => (
            <Table.HeaderCellText align="left">Time</Table.HeaderCellText>
          ),
          cell: ({ cell }) =>
            cell ? (
              <Text
                css={{ whiteSpace: 'nowrap' }}
                color="dim"
                size={1}
                weight="medium"
              >
                {formatRelativeTimestamp(cell.getValue(), {
                  shouldSuffixRelativeTimeAgo: !isMobile,
                })}
              </Text>
            ) : (
              <Dash size={1} />
            ),
          enableSorting: true,
          size: 60,
        }),
      ],
      []
    ),
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  if (collectionSalesHistoryQuery.isLoading) {
    return (
      <Flex
        css={{
          height: 340,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <SpinnerStroked size={32} />
      </Flex>
    );
  }

  if (salesData.length === 0) {
    return (
      <Empty
        heading="No activity"
        subheading="Sales history for NFTs in this collection will be displayed here."
      />
    );
  }

  const rows = table.getRowModel().rows;

  return (
    <Table.Root
      css={{
        paddingBottom: '$6',
        '@bp2': {
          paddingTop: '$4',
          paddingBottom: '$8',
        },
      }}
    >
      <Box>
        {table.getHeaderGroups().map((headerGroup) => (
          <Table.Header key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              const { column, id } = header;

              const size = column.getSize();

              return (
                <Table.HeaderCell
                  key={id}
                  style={{
                    flexGrow: size,
                    flexShrink: 0,
                    minWidth: 0,
                    width: size,
                  }}
                  hasSort={column.getCanSort()}
                  sortDirection={column.getIsSorted() || undefined}
                  onClick={() => {
                    column.toggleSorting();
                  }}
                >
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                  <Table.HeaderCellSortIndicator />
                </Table.HeaderCell>
              );
            })}
          </Table.Header>
        ))}
      </Box>
      <Box css={{ marginTop: '$3' }}>
        <Table.Body ref={parentRef} style={{ height: isMobile ? 540 : 760 }}>
          <Box
            style={{
              height: `${rowVirtualizer.getTotalSize()}px`,
              width: '100%',
              position: 'relative',
            }}
          >
            {rowVirtualizer.getVirtualItems().map((virtualRow) => {
              const row = rows[virtualRow.index];

              if (!row) return null;

              return (
                <TableRowVirtualized
                  key={virtualRow.index}
                  style={{
                    height: `${virtualRow.size}px`,
                    transform: `translateY(${virtualRow.start}px)`,
                  }}
                >
                  {row.getVisibleCells().map((cell, index) => {
                    const size = cell.column.getSize();

                    return (
                      <Table.Cell
                        key={index}
                        style={{
                          flexGrow: size,
                          flexShrink: 0,
                          minWidth: 0,
                          width: size,
                        }}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </Table.Cell>
                    );
                  })}
                </TableRowVirtualized>
              );
            })}
          </Box>
        </Table.Body>
      </Box>
      <ActivityIndicator isActive={collectionSalesHistoryQuery.isFetching} />
    </Table.Root>
  );
}

const TableRowVirtualized = styled(Table.Row, {
  display: 'flex',
  position: 'absolute',
  top: 0,
  left: 0,
});

interface UserTableCellProps {
  user: SalesHistoryItem['seller'] | undefined;
}

function UserTableCell(props: UserTableCellProps) {
  const { user } = props;
  return (
    <Box css={{ position: 'relative', zIndex: 2 }}>
      {user ? (
        <UserTag
          nameVariant="prefer-username"
          size={2}
          type="avatar-text"
          user={user}
        />
      ) : (
        <Dash />
      )}
    </Box>
  );
}

interface ArtworkTableCellProps {
  artwork: SalesHistoryItem['artwork'];
}

function ArtworkTableCell(props: ArtworkTableCellProps) {
  const { artwork } = props;

  if (!artwork) {
    return <Box />;
  }

  const posterImageUrl = buildPosterUrl(artwork, {
    bg: 'F2F2F2',
    w: 128,
    fm: 'jpg',
  });
  const assetImageUrl = buildArtworkAssetUrl(
    { w: 128, fm: 'jpg', auto: 'format,compress' },
    artwork
  );

  return (
    <Flex css={{ alignItems: 'center' }}>
      <Box css={{ marginRight: '$2', '@bp2': { marginRight: '$4' } }}>
        <CollectionLogo
          disableImgixOptimization
          imageUrl={posterImageUrl || assetImageUrl}
          size={{ '@initial': 3, '@bp2': 5 }}
        />
      </Box>
      <Text
        size={{
          '@initial': 1,
          '@bp2': 2,
        }}
        weight="medium"
        css={{
          minWidth: 0,
        }}
        ellipsis
      >
        {artwork.name}
      </Text>
    </Flex>
  );
}
