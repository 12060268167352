import { keepPreviousData } from '@tanstack/react-query';
import { isEmpty } from 'ramda';

import Modal from 'components/base/Modal';
import ModalContainer from 'components/modals/common/ModalContainer';
import VirtualisedUserListEmptyState from 'components/modals/virtualized/VirtualisedUserListEmptyState';
import VirtualisedUserListLoading from 'components/modals/virtualized/VirtualisedUserListLoading';
import VirtualizedUserList from 'components/modals/virtualized/VirtualizedUserList';

import { useInfiniteCollectionOwners } from 'gql/hasura/queries/collection-owners.generated';
import { useInfiniteQueryItems } from 'hooks/use-infinite-query-items';
import useModal from 'hooks/use-modal';
import { hasuraPaginator } from 'utils/react-query';

interface CollectionOwnersModalProps {
  contractSlug: string;
  currentUserPublicKey: string | null; // TODO: migrate to `Address | null`
}

export default function CollectionOwnersModal(
  props: CollectionOwnersModalProps
) {
  const { contractSlug, currentUserPublicKey } = props;

  const { activeModal } = useModal();

  const isModalOpen = activeModal ? activeModal.type === 'OWNED_BY' : false;

  const collectionOwnersQuery = useInfiniteCollectionOwners(
    {
      contractSlug,
      currentUserPublicKey: currentUserPublicKey || '', // TODO: update query to make this optional
      ...hasuraPaginator.initialPageParam,
    },
    {
      initialPageParam: hasuraPaginator.initialPageParam,
      getNextPageParam: hasuraPaginator.getNextPageParam,
      placeholderData: keepPreviousData,
      enabled: isModalOpen,
    }
  );

  const collectors = useInfiniteQueryItems(collectionOwnersQuery, 'publicKey');

  const noResults = isEmpty(collectors);

  return (
    <ModalContainer modalKey="OWNED_BY">
      <Modal.Content
        height="normal"
        header={<Modal.Header title="Collected by" />}
        size={0}
      >
        <CollectionOwnersModalBody
          isLoading={collectionOwnersQuery.isLoading}
          noResults={noResults}
        >
          <VirtualizedUserList
            users={collectors}
            handleNextPage={() => collectionOwnersQuery.fetchNextPage()}
            isFetchingNextPage={collectionOwnersQuery.isFetchingNextPage}
            hasNextPage={Boolean(collectionOwnersQuery.hasNextPage)}
            onFollowUpdate={() => {
              collectionOwnersQuery.refetch();
            }}
          />
        </CollectionOwnersModalBody>
      </Modal.Content>
    </ModalContainer>
  );
}

type CollectionOwnersModalBodyProps = {
  isLoading: boolean;
  noResults: boolean;
  children: React.ReactNode;
};

function CollectionOwnersModalBody(props: CollectionOwnersModalBodyProps) {
  const { isLoading, noResults, children } = props;

  if (isLoading) {
    return <VirtualisedUserListLoading />;
  }

  if (noResults) {
    return (
      <VirtualisedUserListEmptyState
        heading="No owners"
        subheading="This collection has no owners."
      />
    );
  }

  return <>{children}</>;
}
