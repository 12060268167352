import { EthLabel } from 'components/EthLabel';
import Dash from 'components/base/Dash';

import { formatETHRounded } from 'utils/formatters';

import SingleValue from './SingleValue';

type EthValueProps = {
  ethValue: number;
  textAlign?: 'left' | 'right';
  type: 'price' | 'total';
};

export default function EthValue(props: EthValueProps) {
  const { textAlign = 'right', ethValue, type } = props;

  if (ethValue === 0) {
    return (
      <SingleValue css={{ textAlign }}>
        {type === 'price' ? 'Free' : <Dash />}
      </SingleValue>
    );
  }

  return (
    <SingleValue css={{ textAlign }}>
      {formatETHRounded(props.ethValue)}&nbsp;
      <EthLabel />
    </SingleValue>
  );
}
