import { keepPreviousData } from '@tanstack/react-query';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { clamp } from 'ramda';
import { ReactNode, useEffect, useRef } from 'react';
import type { Address } from 'viem';

import CardGrid from 'components/CardGrid';
import Empty from 'components/Empty';
import Spinner from 'components/Spinner';
import Box from 'components/base/Box';
import Button from 'components/base/Button';
import ButtonWithClose from 'components/base/ButtonWithClose';
import ButtonWithCount from 'components/base/ButtonWithCount';
import Flex from 'components/base/Flex';
import Heading from 'components/base/Heading';
import Text from 'components/base/Text';
import ArtworkCardAlgolia, {
  ArtworkCardAlgoliaProps,
} from 'components/cards/artwork/ArtworkCardAlgolia';
import ArtworkCardSkeletonGrid, {
  mapMarketAvailabilityToSkeletonVariant,
} from 'components/cards/artwork/ArtworkCardSkeletonGrid';
import InfiniteScrollButton from 'components/feed/InfiniteScrollButton';
import FiltersSection from 'components/filters/FiltersSection';
import InlineFilterGroupDivider from 'components/filters/InlineFilterGroupDivider';
import MarketAvailabilityFilter from 'components/filters/MarketAvailabilityFilter';
import NftsSort from 'components/filters/NftsSort';
import { useHomeTabCurationOption } from 'components/home-tab';
import EditCollectionModal from 'components/modals/EditCollectionModal';
import CollectionAttributeModal, {
  getFacetValues,
} from 'components/modals/v2/CollectionAttributeModal';
import { hasPublicKey, hasUser } from 'contexts/auth/helpers';
import useAuth from 'contexts/auth/useAuth';
import { NO_CONTENT } from 'copy/empty';

import MediaIcons from 'assets/images/media.svg';
import { useHomeNftIdsByAccountAddress } from 'gql/api/queries/home-nft-ids-by-account-address.generated';
import { CollectionFragmentExtended } from 'gql/hasura/hasura-fragments.generated';
import { useNftFilters } from 'hooks/filters/use-nft-filters';
import { useNftFiltersUrlSync } from 'hooks/filters/use-nft-filters-url-sync';
import useAlgoliaArtworks, {
  getAlgoliaArtworksCount,
} from 'hooks/queries/algolia/use-algolia-artworks';
import useAlgoliaFacets from 'hooks/queries/algolia/use-algolia-facets';
import useAssetFallbackByArtworkIds from 'hooks/queries/hasura/artworks/use-asset-fallback-by-artwork-ids';
import useCollectionArtworkCreator, {
  CollectionArtworkCreator,
} from 'hooks/queries/hasura/collections/use-collection-artwork-creator';
import { ChainId } from 'lib/chains';
import { mapMarketAvailabilityToAlgoliaSearchIndex } from 'utils/algolia';
import { mapFailedArtworksToIds, findFallbackAsset } from 'utils/assets';
import { isSharedContract } from 'utils/collections';
import {
  getUsernameOrAddress,
  getUsernameOrTruncatedAddress,
  isNumberType,
  notEmptyOrNil,
} from 'utils/helpers';
import { getHomeTabNftIdMap } from 'utils/home-tab/utils';
import { getPath } from 'utils/router';
import { pluralizeWord } from 'utils/strings';
import { areKeysEqual } from 'utils/users';

import { AlgoliaArtwork } from 'types/Algolia';
import { HomeTabCurationProps } from 'types/HomeTab';
import { MarketAvailability } from 'types/MarketAvailability';
import { NftSortOrder } from 'types/Nft';

type CollectionArtworksCollection = Pick<
  CollectionFragmentExtended,
  'contractAddress' | 'contractType'
>;

interface CollectionArtworksProps {
  collectionChainId: ChainId;
  collection: CollectionArtworksCollection;
  artworkCreator?: CollectionArtworkCreator;
  isOwnerOnCollection: boolean;
  isOwnerOrAdmin: boolean;
  burnedTokensCount: number | null;
  mintedTokensCount: number | null;
  unstyledCollection: boolean;
  isEmptyCollection: boolean;
}

type CollectionArtworksRootProps = CollectionArtworksProps;

export default function CollectionArtworks(props: CollectionArtworksRootProps) {
  if (isSharedContract(props.collection.contractType)) {
    return <SharedCollectionArtworks {...props} />;
  }

  return <CollectionArtworksBase {...props} />;
}

function SharedCollectionArtworks(props: CollectionArtworksRootProps) {
  const { filters } = useNftFilters(props.collection.contractAddress);

  const artworkCreatorQuery = useCollectionArtworkCreator({
    contractAddress: props.collection.contractAddress,
    // @ts-expect-error null-checks
    artworkCreatorPublicKey: filters.creatorPublicKey,
  });

  return (
    <CollectionArtworksBase
      artworkCreator={artworkCreatorQuery.data}
      {...props}
    />
  );
}

function CollectionArtworksBase(props: CollectionArtworksProps) {
  const {
    collectionChainId,
    collection,
    burnedTokensCount,
    mintedTokensCount,
    artworkCreator,
    isOwnerOnCollection,
    isOwnerOrAdmin,
    isEmptyCollection,
    unstyledCollection,
  } = props;

  const { contractAddress, contractType } = collection;

  const shouldPollRef = useRef(false);

  const { filters, resetCreatorFilter, resetFilters } =
    useNftFilters(contractAddress);

  const isSharedCollection = isSharedContract(contractType);

  useNftFiltersUrlSync(contractAddress);

  const { data, isLoading, fetchNextPage, isFetching, hasNextPage } =
    useAlgoliaArtworks(
      {
        contractAddress,
        filters,
        showModeratedWorks: isOwnerOrAdmin,
      },
      {
        enabled: true,
        shouldPoll: ({ algoliaArtworksCount }) => {
          if (filters.hasFilters) {
            return false;
          }

          if (shouldPollRef.current === true) {
            return true;
          }

          if (isNumberType(mintedTokensCount) && mintedTokensCount > 0) {
            // when the algolia count is below the contract count we
            // can assume there are still some artworks to index
            return algoliaArtworksCount < mintedTokensCount;
          } else {
            return false;
          }
        },
      }
    );

  const algoliaNftsCount = data ? getAlgoliaArtworksCount(data) : null;

  /**
   * Only compare counts when the Algolia market availability filter is not set, otherwise we will
   * compare the number of minted tokens on a contract, to the number of tokens related to a filtered view.
   */
  const canCompareCounts =
    filters.hasFilters === false &&
    isNumberType(burnedTokensCount) &&
    isNumberType(mintedTokensCount) &&
    isNumberType(algoliaNftsCount);

  const getPendingTransactionCount = () => {
    if (!canCompareCounts) return 0;

    const expectedIndexedItems = mintedTokensCount - burnedTokensCount;
    const actualIndexedItems = algoliaNftsCount;

    try {
      return clamp(
        0,
        expectedIndexedItems,
        expectedIndexedItems - actualIndexedItems
      );
    } catch (_error) {
      return 0;
    }
  };

  const pendingTransactionCount = getPendingTransactionCount();

  useEffect(() => {
    /**
     * If the pending transaction count is ever greater than 0, start polling.
     * This helps with an edge case with Highlight mints, where the artwork is initially moved to a FAILED status.
     */
    if (pendingTransactionCount > 0) {
      shouldPollRef.current = true;
    }
  }, [pendingTransactionCount]);

  const artworks = data ? data.pages.flatMap((page) => page.hits) : [];

  const attributeFacetQuery = useAlgoliaFacets(
    {
      searchIndex: mapMarketAvailabilityToAlgoliaSearchIndex(
        filters.marketAvailability,
        filters.sortOrder
      ),
      searchTerm: '',
      options: {
        facets: ['attributes.*'],
        hitsPerPage: 0,
        facetFilters: [
          'moderationStatus:ACTIVE',
          'isDeleted:false',
          `collection.contractAddress:${contractAddress}`,
        ],
      },
    },
    {
      enabled: !isSharedCollection,
      refetchOnWindowFocus: false,
      placeholderData: keepPreviousData,
    }
  );

  const attributeCategories = attributeFacetQuery.data
    ? getFacetValues(attributeFacetQuery.data)
    : [];

  const supportsAttributes =
    !isSharedCollection && notEmptyOrNil(attributeCategories);

  return (
    <Box>
      {!isEmptyCollection && (
        <FiltersSection.Root>
          <Flex
            css={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              gap: '$2',
            }}
          >
            <Box
              css={{
                // TODO: find a way to reuse these styles, with dynamic media queries
                gap: '$2',
                width: '100%',

                '@bp0-max': {
                  display: 'grid',
                  gridTemplateColumns: 'repeat(2, 1fr)',
                  flexGrow: 1,

                  [`& ${ButtonWithCount.Root}`]: {
                    justifyContent: 'space-between',
                  },
                },

                '@bp0': {
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  width: 'auto',
                },
              }}
            >
              <MarketAvailabilityFilter contractAddress={contractAddress} />
              {supportsAttributes && (
                <InlineFilterGroupDivider
                  css={{
                    alignSelf: 'center',
                    '@bp0-max': {
                      display: 'none',
                    },
                  }}
                />
              )}
            </Box>
            <Flex css={{ gap: '$2', flexGrow: 1 }}>
              {supportsAttributes && (
                <CollectionAttributeModal
                  categories={attributeCategories}
                  contractAddress={contractAddress}
                  nftCount={attributeFacetQuery.data?.nbHits || 0}
                />
              )}
              <Box css={{ marginLeft: 'auto' }}>
                <NftsSort cacheKey={contractAddress} />
              </Box>
            </Flex>
          </Flex>
          {artworkCreator && (
            <Flex css={{ marginTop: '$2' }}>
              <ButtonWithClose
                onClick={() => {
                  resetCreatorFilter();
                }}
                size={0}
              >
                {getUsernameOrTruncatedAddress(artworkCreator)}
              </ButtonWithClose>
            </Flex>
          )}
          {filters.media &&
            collection.contractType !== 'FND_BATCH_MINT_REVEAL' && (
              <Flex css={{ marginTop: '$2' }}>
                <ButtonWithClose
                  onClick={() => {
                    resetFilters();
                  }}
                  size={0}
                >
                  Selected
                </ButtonWithClose>
              </Flex>
            )}
        </FiltersSection.Root>
      )}

      <ArtworkResults
        collectionChainId={collectionChainId}
        artworks={artworks}
        isLoading={isLoading}
        contractAddress={contractAddress}
        isOwnerOnCollection={isOwnerOnCollection}
        unstyledCollection={unstyledCollection}
        isEmptyCollection={isEmptyCollection}
        hasFilters={filters.hasFilters}
        resetFilters={() => {
          resetFilters();
        }}
        sortOrder={filters.sortOrder}
        marketAvailability={filters.marketAvailability}
        fetchMoreComponent={
          <InfiniteScrollButton
            handleNextPage={fetchNextPage}
            isFetching={isFetching}
            hasNextPage={hasNextPage}
          />
        }
        pendingTransactionCount={pendingTransactionCount}
      />
    </Box>
  );
}

interface ArtworkResultsProps {
  artworks: AlgoliaArtwork[];
  fetchMoreComponent: ReactNode;
  contractAddress: Address;
  isLoading: boolean;
  isOwnerOnCollection: boolean;
  unstyledCollection: boolean;
  isEmptyCollection: boolean;
  hasFilters: boolean;
  marketAvailability: MarketAvailability | null;
  sortOrder: NftSortOrder;
  resetFilters(): void;
  pendingTransactionCount: number;
  collectionChainId: ChainId;
}

function ArtworkResults(props: ArtworkResultsProps) {
  const {
    artworks,
    isLoading,
    fetchMoreComponent,
    contractAddress,
    isOwnerOnCollection,
    unstyledCollection,
    isEmptyCollection,
    hasFilters,
    marketAvailability,
    sortOrder,
    resetFilters,

    pendingTransactionCount,
  } = props;

  const router = useRouter();
  const auth = useAuth();
  const currentUserPublicKey = hasPublicKey(auth) ? auth.publicKey : null;
  const currentUserUsername = hasUser(auth) ? auth.user.username : null;

  const creatorPublicKeys = artworks.flatMap(
    (artwork) => artwork.creator.publicKey
  );

  const homeNftIdsByAccountAddressQuery = useHomeNftIdsByAccountAddress(
    { accountAddress: currentUserPublicKey as Address },
    {
      enabled:
        Boolean(currentUserPublicKey) &&
        creatorPublicKeys.includes(currentUserPublicKey as Address),
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      placeholderData: keepPreviousData,
    }
  );

  const homeTabNftIdMap = homeNftIdsByAccountAddressQuery.isLoading
    ? {}
    : getHomeTabNftIdMap(homeNftIdsByAccountAddressQuery.data?.home?.items);

  const homeTabCuration: HomeTabCurationProps['homeTabCuration'] = {
    isOnHomeTab: (nftId) => {
      return Boolean(homeTabNftIdMap[nftId]);
    },
    getHomeTabItemId: (nftId) => {
      return homeTabNftIdMap[nftId] as string;
    },
    viewHomeTab: () => {
      router.push(
        `/${getUsernameOrAddress({
          publicKey: currentUserPublicKey as Address,
          username: currentUserUsername,
        })}`
      );
    },
  };

  const hasResults = notEmptyOrNil(artworks);

  const hasPendingTransactions = pendingTransactionCount > 0;

  const fallbackArtworkIds = mapFailedArtworksToIds(artworks);

  const fallbackAssetsQuery = useAssetFallbackByArtworkIds({
    artworkIds: fallbackArtworkIds,
  });

  if (isLoading) {
    return (
      <ArtworkCardSkeletonGrid
        variant={
          mapMarketAvailabilityToSkeletonVariant(marketAvailability) ||
          'auction'
        }
      />
    );
  }

  if (hasResults) {
    return (
      <>
        <CardGrid.Root>
          {hasPendingTransactions && (
            <PendingTransactionPlaceholder
              pendingCount={pendingTransactionCount}
            />
          )}
          {artworks.map((artwork) => {
            const fallbackAsset = findFallbackAsset(
              fallbackAssetsQuery.data?.asset,
              artwork?.id
            );

            const isCreator = areKeysEqual([
              currentUserPublicKey,
              artwork.creator.publicKey,
            ]);

            return (
              <CollectionPageArtworkCard
                key={artwork.objectID}
                artwork={artwork}
                marketAvailability={marketAvailability}
                sortOrder={sortOrder}
                fallbackAsset={fallbackAsset}
                canCurateHomeTab={isCreator}
                homeTabCuration={homeTabCuration}
              />
            );
          })}
        </CardGrid.Root>
        {fetchMoreComponent}
      </>
    );
  }

  if (hasPendingTransactions) {
    return (
      <CardGrid.Root>
        <PendingTransactionPlaceholder pendingCount={pendingTransactionCount} />
      </CardGrid.Root>
    );
  }

  if (hasFilters) {
    return (
      <Empty.WithFilters
        message="Adjust the selected filters to see more of this Collection."
        onClear={() => resetFilters()}
      />
    );
  }

  if (isOwnerOnCollection && unstyledCollection) {
    return (
      <Flex
        css={{
          paddingY: '$5',
          marginX: 'auto',
          maxWidth: 500,
          minHeight: 400,
          '@bp1': {
            minHeight: 600,
          },
        }}
        center
        expandVertical
      >
        <Heading
          size={{ '@initial': 4, '@bp1': 5 }}
          css={{ textAlign: 'center', marginBottom: '$5' }}
        >
          Customize your collection
        </Heading>
        <Text
          css={{
            textAlign: 'center',
            marginX: 'auto',
            marginBottom: '$7',
            maxWidth: '40ch',
          }}
          color="dim"
          lineHeight={3}
        >
          Before you mint an NFT to your collection, customize it by uploading a
          logo, cover image, and description.
        </Text>
        <EditCollectionModal
          contractAddress={contractAddress}
          collectionSaleType={null}
          size={{ '@initial': 0, '@bp2': 2 }}
          variant="primary"
        />
      </Flex>
    );
  }

  if (isOwnerOnCollection && isEmptyCollection) {
    return (
      <Flex
        css={{
          paddingY: '$5',
          minHeight: 400,
          '@bp1': {
            minHeight: 600,
          },
        }}
        center
        expandVertical
      >
        <MediaIcons />

        <Heading
          size={{ '@initial': 4, '@bp1': 5 }}
          css={{ textAlign: 'center', marginBottom: '$5', paddingTop: '$6' }}
        >
          Add NFTs to your collection
        </Heading>
        <Text
          css={{
            textAlign: 'center',
            marginX: 'auto',
            marginBottom: '$7',
            maxWidth: 280,
          }}
          color="dim"
          lineHeight={3}
        >
          This collection is currently empty. Get it started by minting the
          first NFT.
        </Text>
        <NextLink
          href={getPath.create.mintToCollection({
            contractAddress,
            chainId: props.collectionChainId,
          })}
          passHref
          prefetch={false}
        >
          <Button as="a" size={2} variant="primary">
            Mint NFT
          </Button>
        </NextLink>
      </Flex>
    );
  }

  return (
    <Empty
      heading={NO_CONTENT}
      subheading={
        isEmptyCollection
          ? 'Come back later to see new works in the collection.'
          : 'Select another filter to show new results.'
      }
      icon="grid"
    />
  );
}

type CollectionPageArtworkCardProps = HomeTabCurationProps &
  Omit<ArtworkCardAlgoliaProps, 'menuOptions'>;

function CollectionPageArtworkCard(props: CollectionPageArtworkCardProps) {
  const { canCurateHomeTab, homeTabCuration } = props;

  const nftId = props.artwork.id;

  const homeTabOption = useHomeTabCurationOption({
    homeTabCuration,
    canCurateHomeTab,
    nftId,
    type: 'profile',
  });

  const menuOptions = homeTabOption ? [homeTabOption] : [];

  return <ArtworkCardAlgolia {...props} menuOptions={menuOptions} />;
}

function PendingTransactionPlaceholder(props: { pendingCount: number }) {
  const { pendingCount } = props;

  return (
    <Box
      css={{
        background: '$black4',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '$2',
        minHeight: 490, // needed to prevent grid from collapsing when there are no artworks
      }}
    >
      <Box
        css={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '$4',
        }}
      >
        <Box css={{ color: 'black' }}>
          <Spinner size={2} />
        </Box>
        <Box>
          <Text color="dim">
            Syncing {pendingCount} {pluralizeWord('NFT', pendingCount)}
          </Text>
        </Box>
      </Box>
    </Box>
  );
}
