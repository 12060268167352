import { ArrowDownIcon } from '@f8n/icons';
import { onGridPx } from '@f8n/tokens';
import { styled } from '@f8n-frontend/stitches';

import Skeleton from './Skeleton';
import Text from './Text';

const EDGE_PADDING_MOBILE = onGridPx(5);
const EDGE_PADDING = onGridPx(8);

const BORDER = 'solid 1px $black5';

const TableBody = styled('div', {
  border: BORDER,
  borderRadius: '$3',
  boxShadow: '$soft0',
  width: '100%',
  overflow: 'auto',
});

const TableRow = styled('div', {
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  paddingY: '$3',

  '&:not(:last-of-type)': {
    borderBottom: BORDER,
  },

  '@bp2': {
    paddingY: '$4',
  },
});

/**
 * Used when you want to have multiple rows in a table that are grouped together visually
 * e.g. adding a full-width cta to a row
 */
const TableRowGroup = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$3',
  paddingY: '$3',

  '&:not(:last-of-type)': {
    borderBottom: BORDER,
  },

  [`& ${TableRow}`]: {
    borderBottom: 'none !important',
    paddingY: 0,
  },

  '@bp2': {
    paddingY: '$4',
  },
});

const TableHeader = styled('div', {
  display: 'flex',
  gap: '$0',
});

const TableHeaderSortIndicatorWrapper = styled('div', {
  opacity: 0,
  transition: '$1 $ease opacity, $2 $ease transform',
});

const TableHeaderCellText = styled(Text, {
  fontFamily: 'inherit',
  transition: '$1 $ease color',

  variants: {
    align: {
      left: {},
      right: {
        marginLeft: 'auto',
        textAlign: 'right',
      },
    },
    isActive: {
      true: { color: '$black100' },
    },
  },
});
TableHeaderCellText.defaultProps = {
  size: 0,
  weight: 'medium',
  color: 'dim',
  lineHeight: 0,
};

const TableHeaderCell = styled('div', {
  paddingX: '$3',
  fontFamily: 'inherit',
  display: 'flex',
  gap: '$2',
  alignItems: 'center',

  '@bp2': {
    paddingX: '$5',
  },

  [`& ${TableHeaderSortIndicatorWrapper}`]: {
    display: 'none',
  },

  variants: {
    hasSort: {
      true: {
        cursor: 'pointer',

        [`& ${TableHeaderSortIndicatorWrapper}`]: {
          display: 'block',
          opacity: 0.1,
        },

        '&:hover': {
          [`& ${TableHeaderCellText}`]: {
            color: '$black100',
          },

          [`& ${TableHeaderSortIndicatorWrapper}`]: {
            opacity: 0.5,
          },
        },
      },
      false: {
        cursor: 'not-allowed',
      },
    },
    sortDirection: {
      asc: {
        [`& ${TableHeaderCellText}`]: {
          color: '$black100',
        },

        [`& ${TableHeaderSortIndicatorWrapper}`]: {
          opacity: '1 !important',
          transform: 'rotate(180deg)',
        },
      },
      desc: {
        [`& ${TableHeaderCellText}`]: {
          color: '$black100',
        },

        [`& ${TableHeaderSortIndicatorWrapper}`]: {
          opacity: '1 !important',
        },
      },
    },
  },
});

function TableHeaderSortIndicator() {
  return (
    <TableHeaderSortIndicatorWrapper>
      <ArrowDownIcon size={0} />
    </TableHeaderSortIndicatorWrapper>
  );
}

const TableCell = styled('div', {
  paddingX: '$3',
  fontWeight: '$medium',

  [`& ${Skeleton.Block}`]: {
    minWidth: '24px',
    width: '100%',
    height: '24px',
  },

  '@bp2': {
    paddingX: '$4',
  },

  variants: {
    align: {
      left: {},
      right: {
        textAlign: 'right',
      },
    },
    variant: {
      dim: {
        color: '$black70',
      },
      strong: {
        fontWeight: '$semibold',
      },
    },
  },
});

const TableRoot = styled('div', {
  variants: {
    cellPadding: {
      narrow: {
        [`${TableCell}, ${TableHeaderCell}`]: {
          paddingX: '$3',
        },
      },
      normal: {},
    },
    edgePadding: {
      padded: {
        [`${TableCell}, ${TableHeaderCell}`]: {
          '&:first-of-type': {
            paddingLeft: EDGE_PADDING_MOBILE,
          },

          '&:last-of-type': {
            paddingRight: EDGE_PADDING_MOBILE,
          },

          '@bp0': {
            '&:first-of-type': {
              paddingLeft: EDGE_PADDING,
            },

            '&:last-of-type': {
              paddingRight: EDGE_PADDING,
            },
          },
        },
      },
      none: {
        [`${TableCell}, ${TableHeaderCell}`]: {
          '&:first-of-type': {
            paddingLeft: '0px',
          },

          '&:last-of-type': {
            paddingRight: '0px',
          },
        },
      },
    },
  },

  defaultVariants: {
    cellPadding: 'normal',
    edgePadding: 'padded',
  },
});

type SkeletonTableProps = {
  rows: number;
  /**
   * Relative widths of columns, in %
   * @example [50, 25, 25] for a 3 column table with 50% width for the first column, 25% for the second and third
   */
  columns: number[];
  /**
   * Relative widths of skeletons inside columns, in %
   * @example [null, 50, 10] for a 3 column table with 100% width for the first column (the default), and 50% for the second.
   */
  skeletonWidths?: (number | null)[];
};

export function SkeletonTable(props: SkeletonTableProps) {
  const { columns, rows, skeletonWidths = [] } = props;

  return (
    <TableRoot>
      <TableBody>
        {Array.from({ length: rows }).map((_, index) => (
          <TableRow
            key={`row-${index}`}
            css={{ justifyContent: 'space-between' }}
          >
            {columns.map((width, index) => (
              <TableCell
                key={`cell-${index}`}
                css={{
                  flexGrow: 1,
                }}
                style={{
                  flexBasis: `${width}%`,
                }}
              >
                <Skeleton.Block
                  style={{
                    maxWidth: skeletonWidths[index]
                      ? `${skeletonWidths[index]}%`
                      : '100%',
                  }}
                />
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </TableRoot>
  );
}

const Table = {
  Body: TableBody,
  Cell: TableCell,
  Root: TableRoot,
  Row: TableRow,
  RowGroup: TableRowGroup,
  Header: TableHeader,
  HeaderCell: TableHeaderCell,
  HeaderCellText: TableHeaderCellText,
  HeaderCellSortIndicator: TableHeaderSortIndicator,
};

export default Table;
