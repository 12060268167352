import {
  NotificationsIcon as BuyNowIcon,
  AuctionIcon,
  SparklesIcon as MintIcon,
  PlusIcon as ListIcon,
  OfferIcon,
} from '@f8n/icons';

import Badge, { BadgeProps } from 'components/base/Badge';
import Text from 'components/base/Text';
import { activityTypeLabelMap } from 'copy/activity';

import { ActivityType } from 'types/Activity';

interface ActivityBadgeProps {
  value: ActivityType;
}

export default function ActivityBadge(props: ActivityBadgeProps) {
  const { value } = props;

  if (!value) {
    return null;
  }

  const { variant, icon } = getBadgeDetails(value);

  return (
    <Badge
      variant={variant}
      size={0}
      css={{
        gap: '$1',
        whiteSpace: 'nowrap',
        '@bp2-max': { svg: { display: 'none' } },
      }}
    >
      {icon}
      <Text weight="semibold">{activityTypeLabelMap[value]}</Text>
    </Badge>
  );
}

type BadgeDetails = {
  icon: React.ReactNode | null;
  variant: BadgeProps['variant'];
};

const getBadgeDetails = (value: ActivityType): BadgeDetails => {
  switch (value) {
    case 'auction':
      return {
        variant: 'blue',
        icon: <AuctionIcon size={0} />,
      };
    case 'offer':
      return {
        variant: 'blue',
        icon: <OfferIcon size={0} />,
      };
    case 'buy_now':
      return {
        variant: 'blue',
        icon: <BuyNowIcon size={0} />,
      };
    case 'mint':
      return {
        variant: 'orange',
        icon: <MintIcon size={0} />,
      };
    case 'list':
      return {
        variant: 'purple',
        icon: <ListIcon size={0} />,
      };
    case 'private_sale':
      return {
        variant: 'outline',
        icon: null,
      };
  }
};
