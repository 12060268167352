import { minutesToSeconds } from 'date-fns';
import { match, P } from 'ts-pattern';
import { z } from 'zod';

import { MIN_LIST_PRICE } from 'lib/constants';
import { splitRecipientSchema } from 'schemas/split';
import { chainIdSchema } from 'schemas/url/chain';
import {
  COLLECTION_NAME_MAX_LENGTH_ERROR,
  SYMBOL_CHARACTER_LIMIT_ERROR,
  SYMBOL_SPECIAL_CHARACTER_ERROR,
} from 'utils/collections';
import { checkIsValidSlug } from 'utils/slug';
import { daysToSeconds } from 'utils/time';

import { sumOfSplits } from './mint';

const SPLIT_RECIPIENTS = z.array(splitRecipientSchema);

type CreateDurationSchemaOptions = {
  minimumMinutes: number;
  maximumDays: number;
};

export const createDurationSchema = (options: CreateDurationSchemaOptions) => {
  const { minimumMinutes, maximumDays } = options;

  return z
    .number()
    .min(minutesToSeconds(minimumMinutes), {
      message: `Cannot be less than ${minimumMinutes} minutes`,
    })
    .max(daysToSeconds(maximumDays), {
      message: `Cannot be more than ${maximumDays} days`,
    })
    .nonnegative()
    .int();
};

export const COMMON_SCHEMA_FIELDS = {
  COLLECTION_NAME: z
    .string({ required_error: 'Collection name is required' })
    .min(1, { message: 'Collection name is required' })
    .max(48, { message: COLLECTION_NAME_MAX_LENGTH_ERROR }),
  COLLECTION_SYMBOL: z
    .string({ required_error: 'Symbol is required' })
    .min(1, { message: 'Symbol is required' })
    .max(8, SYMBOL_CHARACTER_LIMIT_ERROR)
    .toUpperCase()
    .refine(checkIsValidSlug, { message: SYMBOL_SPECIAL_CHARACTER_ERROR }),
  WORLD_OPTIONS: z
    .object({
      exhibitionId: z
        .number({ invalid_type_error: 'Select a gallery to list in' })
        .optional(),
      worldEnabled: z.boolean(),
    })
    .refine(
      (schema) => {
        const isValid = match(schema)
          /**
           * valid exhibition ID must be greater than 0
           */
          .with(
            { worldEnabled: true, exhibitionId: P.select(P.number) },
            (exhibitionId) => exhibitionId > 0
          )
          .with({ worldEnabled: true }, () => false)
          .otherwise(() => true);

        return isValid;
      },
      {
        path: ['exhibitionId'],
        message: 'Select a gallery to list in',
      }
    ),
  SPLIT_RECIPIENTS,
  SPLIT_OPTIONS: z
    .object({
      enabled: z.boolean(),
      recipients: SPLIT_RECIPIENTS,
    })
    .refine(
      (schema) => (schema.enabled ? schema.recipients.length > 1 : true),
      {
        path: ['recipients'],
        message: 'You need at least two recipients',
      }
    )
    .refine(
      (schema) => (schema.enabled ? schema.recipients.length < 5 : true),
      {
        path: ['recipients'],
        message: 'You can have at most four recipients',
      }
    )
    .refine(
      (schema) =>
        schema.enabled && Array.isArray(schema.recipients)
          ? sumOfSplits(schema.recipients)
          : true,
      {
        path: ['recipients'],
        message: 'The split percentages need to add up to 100',
      }
    ),
  DROP_NFT_COUNT: z.coerce
    .number({
      required_error: 'Enter a number',
      invalid_type_error: 'Not a valid number',
    })
    .int()
    .positive()
    .min(2)
    .max(10_000),
  DROP_MIN_PRICE: z.union([
    z.literal(0, {
      required_error: 'Enter a mint price',
      invalid_type_error: 'Not a valid mint price',
    }),
    z
      .number({
        required_error: 'Enter a mint price',
        invalid_type_error: 'Not a valid mint price',
      })
      .min(MIN_LIST_PRICE),
  ]),
  AUCTION_DURATION: createDurationSchema({
    minimumMinutes: 15,
    maximumDays: 7,
  }),
  WORLD_ID: z.number().int().positive(),
  CHAIN_ID: chainIdSchema,
};

export const splitRecipientsSchema = SPLIT_RECIPIENTS.refine(
  (splits) => splits.length <= 4,
  { message: 'You can have at most four recipients' }
).refine(sumOfSplits, {
  message: 'The split percentages need to add up to 100',
});
